define('ui/services/catalog', ['exports', 'ember', 'ui/utils/util', 'ui/utils/constants'], function (exports, _ember, _uiUtilsUtil, _uiUtilsConstants) {

  var RANCHER_VERSION = 'rancherVersion';

  exports['default'] = _ember['default'].Service.extend({
    settings: _ember['default'].inject.service(),
    store: _ember['default'].inject.service('store'),
    userStore: _ember['default'].inject.service('user-store'),
    projects: _ember['default'].inject.service(),

    templateCache: null,
    catalogs: null,

    templateBase: _ember['default'].computed('projects.current.orchestration', function () {
      return this.get('projects.current.orchestration') || 'cattle';
    }),

    reset: function reset() {
      this.setProperties({
        templateCache: null,
        catalogs: null
      });
    },

    refresh: function refresh() {
      var store = this.get('store');

      this.reset();
      return store.request({
        url: this.get('app.catalogEndpoint') + '/templates?refresh&action=refresh',
        method: 'POST',
        timeout: null });
    },

    // I'm willing to wait...
    fetchCatalogs: function fetchCatalogs() {
      return this.get('store').request({ url: this.get('app.catalogEndpoint') + '/catalogs' });
    },

    getTemplateFromCache: function getTemplateFromCache(id) {
      return this.get('store').getById('template', id);
    },

    getVersionFromCache: function getVersionFromCache(id) {
      return this.get('store').getById('templateversion', id);
    },

    fetchTemplate: function fetchTemplate(id) {
      var upgrade = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      var type = undefined,
          cached = undefined;
      if (upgrade === true) {
        type = 'templateversions';
        cached = this.getVersionFromCache(id);
      } else {
        type = 'templates';
        cached = this.getTemplateFromCache(id);
      }

      if (cached) {
        return _ember['default'].RSVP.resolve(cached);
      }

      var url = this._addLimits(this.get('app.catalogEndpoint') + '/' + type + '/' + id);
      return this.get('store').request({ url: url });
    },

    fetchTemplates: function fetchTemplates(params) {
      var _this = this;

      var cache = this.get('templateCache');
      var templateBase = params.templateBase || this.get('templateBase');
      var catalogId = params.catalogId;
      var plusInfra = params.plusInfra || false;

      var qp = {
        'category_ne': 'system'
      };

      if (catalogId && catalogId !== 'all') {
        qp['catalogId'] = catalogId;
      }

      // If the catalogIds dont match we need to go get the other catalog from the store since we do not cache all catalogs
      if (cache && cache.catalogId === catalogId) {
        return _ember['default'].RSVP.resolve(this.filter(cache, params.category, templateBase, plusInfra));
      }

      var url = this._addLimits(this.get('app.catalogEndpoint') + '/templates', qp);
      return this.get('store').request({ url: url }).then(function (res) {
        res.catalogId = catalogId;
        _this.set('templateCache', res);
        return _this.filter(res, params.category, templateBase, plusInfra);
      })['catch'](function (err) {
        if (params.allowFailure) {
          return _this.filter([], params.category, templateBase, plusInfra);
        } else {
          return _ember['default'].RSVP.reject(err);
        }
      });
    },

    filter: function filter(data, category, templateBase, plusInfra) {
      var bases = [];

      category = (category || 'all').toLowerCase();

      if (templateBase === 'cattle') {
        bases.push('');
      } else {
        bases.push(templateBase);
      }

      if (plusInfra) {
        bases.push(_uiUtilsConstants['default'].EXTERNAL_ID.KIND_INFRA);
      }

      var categories = [];
      data.forEach(function (obj) {
        categories.pushObjects(obj.get('categoryArray'));
      });
      categories = (0, _uiUtilsUtil.uniqKeys)(categories);
      categories.unshift('all');

      data = data.filter(function (tpl) {
        if (category !== 'all' && !tpl.get('categoryLowerArray').includes(category)) {
          return false;
        }

        if (!bases.includes(tpl.get('templateBase') || '')) {
          return false;
        }

        return true;
      });

      data = data.sortBy('name');

      return _ember['default'].Object.create({
        categories: categories,
        catalog: data,
        templateBase: templateBase
      });
    },

    _addLimits: function _addLimits(url, qp) {
      var version = this.get('settings.rancherVersion');
      qp = qp || {};

      if (version) {
        qp[RANCHER_VERSION] = version;
      }

      url = (0, _uiUtilsUtil.addQueryParams)(url, qp);

      return url;
    }
  });
});