define('ui/application/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    settings: _ember['default'].inject.service(),

    // GitHub auth params
    queryParams: ['error_description', 'state', 'code', 'isTest', 'isPopup'],

    resourceActions: _ember['default'].inject.service('resource-actions'),
    tooltipService: _ember['default'].inject.service('tooltip'),

    tooltip: _ember['default'].computed.alias('tooltipService.tooltipOpts.type'),
    tooltipTemplate: _ember['default'].computed.alias('tooltipService.tooltipOpts.template'),

    error: null,
    error_description: null,
    state: null,
    code: null,
    isTest: null,
    isPopup: null,

    actions: {
      clickedAction: function clickedAction(actionName) {
        this.get('resourceActions').triggerAction(actionName);
      }
    },

    // currentRouteName is set by Ember.Router
    // but getting the application controller to get it is inconvenient sometimes
    currentRouteNameChanged: (function () {
      this.set('app.currentRouteName', this.get('currentRouteName'));
    }).observes('currentRouteName')

  });
});